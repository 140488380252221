import { useEffect, useState } from 'react';

import RuleDownload from 'components/sub/RuleDownload';
import SubTitle from 'components/sub/SubTitle';
import axios from 'axios';

const AboutRule = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('/api/rule').then((res) => setData(res.data.data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className=" relative w-full h-full">
            <SubTitle src={'/images/subtitle2.jpg'} title="정관" />
            <div className="relative w-full h-fit py-14 lg:py-28">
                <div className="relative w-full h-full wrap">
                    <h5 className="text-2xl">희우장학재단의 정관을 다운받을 수 있습니다.</h5>
                    <RuleDownload rule={data || null} />
                </div>
            </div>
        </div>
    );
};

export default AboutRule;
