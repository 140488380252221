import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import reportWebVitals from './reportWebVitals';
import './index.css';

import { createTheme, MantineProvider } from '@mantine/core';

import RouteScroll from 'components/common/RouteScroll';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
    /** Put your mantine theme override here */
});

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <RouteScroll />
            <MantineProvider theme={theme}>
                <App />
            </MantineProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
