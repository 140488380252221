import SubTitle from 'components/sub/SubTitle';

const AboutCi = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle src={'/images/subtitle3.jpg'} title="재단CI" />
            <div className="relative w-full h-fit py-14 lg:py-28">
                <div className="relative w-full h-full wrap">
                    <h5 className="text-2xl">심볼마크 설명</h5>
                    <div className="grid gap-10 mt-10 lg:mt-16 mb-16 lg:mb-20 text-neutral-500">
                        <p className="lg:text-lg">
                            희우(<span className="pp">喜雨</span>)
                        </p>
                        <p className="lg:text-base">
                            희우장학재단의 심볼은 <b>“오랜 가뭄 끝에 내리는 반가운 비”</b>
                            라는 컨셉으로 학습으로 스스로 배움을 갈망해도 교육을 이어갈 수 없는 학생들을 위해 단비가
                            되어 도움을 주려는 마음을 형상화 하였습니다.
                        </p>
                    </div>
                    <div>
                        <div className="relative w-full h-20 lg:h-36 mb-16">
                            <img
                                className="!relative w-auto h-full mx-auto"
                                alt="favicon"
                                src={'/images/favicon.png'}
                                width={1099}
                                height={1589}
                            />
                        </div>
                        <div className="relative w-full h-fit grid lg:grid-cols-2 gap-10 lg:gap-5 max-w-4xl mx-auto">
                            <div>
                                <div className="relative w-full h-full border py-5 px-28">
                                    <img alt="logo" src={'/images/ci_logo.png'} width={1000} height={785} />
                                </div>
                                <p className="text-base text-neutral-500">희우장학재단 로고</p>
                            </div>
                            <div>
                                <div className="relative w-full h-full border py-5 px-28">
                                    <img alt="logo" src={'/images/ci_slogan.png'} width={1000} height={785} />
                                </div>
                                <p className="text-base text-neutral-500">희우장학재단 슬로건</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutCi;
