import { useEffect, useState } from 'react';
import axios from 'axios';

import Banner from 'components/home/Banner';
import Project from 'components/home/Project';
import Notice from 'components/home/Notice';

const Home = () => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        axios.get('/api/board/main').then((res) => setData(res.data.data));
    }, []);
    return (
        <div>
            <Banner />
            <Project />
            <Notice notice={data} />
        </div>
    );
};

export default Home;
