import { useEffect, useState } from 'react';

import { IconChevronDown, IconLetterQ } from '@tabler/icons-react';

import { QnA } from 'const/table';

const FAQ = ({ qna }: { qna: QnA[] }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        const selected = document.querySelector<HTMLLIElement>('#card' + activeIndex);

        document.querySelector('.active')?.classList.remove('active');

        selected?.classList.add('active');
    });
    return (
        <div className="relative w-full h-full wrap">
            <ul className="faq border-t">
                {qna &&
                    qna.map((v, i) => (
                        <li
                            key={v.title}
                            id={'card' + i.toString()}
                            onClick={() => setActiveIndex(i)}
                            className="card relative w-full border-b py-2 lg:py-5 transition-all duration-500 overflow-hidden"
                        >
                            <div className="relative w-full flex justify-between items-center px-2">
                                <h5 className="flex gap-2 items-center lg:text-2xl">
                                    <IconLetterQ size={30} className="q" />
                                    {v.title}
                                </h5>
                                <IconChevronDown className="icon transition-transform duration-300 text-neutral-500" />
                            </div>
                            <div className="px-5 lg:px-12 py-3 lg:py-5">
                                <p>{v.contents}</p>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default FAQ;
