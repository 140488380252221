import { IconArrowRight } from '@tabler/icons-react';

const BusinessCard = () => {
    return (
        <>
            <div className="relative w-full h-it py-14 lg:py-28 bg-light">
                <div className="relative w-full h-full wrap grid grid-cols-3 gap-1 lg:gap-5">
                    <div className="relative w-full h-full">
                        <div className="gradient relative w-full h-32 lg:h-[350px] overflow-hidden rounded-md">
                            <img
                                className="!relative w-full h-full object-cover object-center lg:hover:scale-125 transition-transform duration-700"
                                src={'/images/project1.jpg'}
                                alt="project1"
                                width={2000}
                                height={1335}
                            />
                        </div>
                        <h5 className="relative -mt-5 z-10 lg:text-3xl">장학금지원</h5>
                    </div>
                    <div className="relative w-full h-full">
                        <div className="gradient relative w-full h-32 lg:h-[350px] overflow-hidden rounded-md">
                            <img
                                className="!relative w-full h-full object-cover object-center lg:hover:scale-125 transition-transform duration-700"
                                src={'/images/project2.jpg'}
                                alt="project1"
                                width={2000}
                                height={1335}
                            />
                        </div>
                        <h5 className="relative -mt-5 z-10 lg:text-3xl">학습지원</h5>
                    </div>
                    <div className="relative w-full h-full">
                        <div className="gradient relative w-full h-32 lg:h-[350px] overflow-hidden rounded-md">
                            <img
                                className="!relative w-full h-full object-cover object-center lg:hover:scale-125 transition-transform duration-700"
                                src={'/images/project3.jpg'}
                                alt="project1"
                                width={2000}
                                height={1335}
                            />
                        </div>
                        <h5 className="relative -mt-5 z-10 lg:text-3xl">상담지원</h5>
                    </div>
                </div>
                <div className="relative w-full h-full wrap text-left pt-14 lg:pt-28 grid gap-8 lg:gap-16">
                    <div>
                        <p className="relative w-fit h-fit mb-2 pt-2">
                            <span className="absolute left-0 top-0 w-1 h-5 rotate-45 bg-point rounded-full z-0"></span>
                            <b>지원자격</b>
                        </p>
                        <p className="lg:text-lg pl-3">
                            대학교 재학중이거나 진급 예정자로 소속 학교의 추천을 받은 자, 입학예정자
                        </p>
                    </div>
                    <div>
                        <p className="relative w-fit h-fit mb-2 pt-2">
                            <span className="absolute left-0 top-0 w-1 h-5 rotate-45 bg-point rounded-full z-0"></span>
                            <b>지원내용</b>
                        </p>
                        <p className="lg:text-lg pl-3">등록금, 학습지원비, 멘토링 및 네트워킹 프로그램</p>
                    </div>
                    <div>
                        <p className="relative w-fit h-fit mb-2 pt-2">
                            <span className="absolute left-0 top-0 w-1 h-5 rotate-45 bg-point rounded-full z-0"></span>
                            <b>지원기간</b>
                        </p>
                        <p className="lg:text-lg pl-3">
                            해당 학교 졸업시까지(정규학기 이내, 매 학기 지속지원 여부 심사)
                        </p>
                    </div>
                    <div>
                        <p className="relative w-fit h-fit mb-2 pt-1.5">
                            <span className="absolute left-0 top-0 w-1 h-5 rotate-45 bg-point rounded-full z-0"></span>
                            <b>선발방법</b>
                        </p>
                        <p className="lg:text-lg pl-3">각 협력학교 추천 및 재단 심사를 통한 선발</p>
                    </div>
                    <div>
                        <p className="relative w-fit h-fit mb-2 pt-2">
                            <span className="absolute left-0 top-0 w-1 h-5 rotate-45 bg-point rounded-full z-0"></span>
                            <b>기타사항</b>
                        </p>
                        <p className="lg:text-lg pl-3">타 재단 및 기관 장학금 중복수혜 불가(단, 국가 장학금 가능)</p>
                    </div>
                </div>
            </div>
            <div
                onClick={() => (window.location.href = '/application')}
                className="relative w-fit h-fit border flex justify-center items-center mx-auto mt-7 lg:mt-14 py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
            >
                <p>장학신청하러가기</p>
                <IconArrowRight />
            </div>
        </>
    );
};

export default BusinessCard;
