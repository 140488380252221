
import { IconHeadset, IconBuildingBank, IconMapPin } from '@tabler/icons-react';

import SubTitle from 'components/sub/SubTitle';
import { Link } from 'react-router-dom';


const Contribution = () => {
    return (
        <div className=' relative w-full h-full'>
            <SubTitle src={"/images/subtitle6.jpg"} title="기부안내" />
            <div className="relative w-full h-fit pt-14">
                <div className="relative w-full h-full wrap ">
                    <div data-aos="fade-up" className="grid gap-8 mb-32">
                        <h5>기회가 간절한 아이들에게 따스한 온기를 전해주세요.</h5>
                        <div>
                            <p className="lg:text-lg">
                                희우장학재단은 학생들을 대상으로 꿈을 이어갈 수 있는 발판을 만들어주고자 합니다.
                            </p>
                            <p className="lg:text-lg mb-3">
                                여러분의 소중한 기부금은 아이들을 위한 양질의 교육 환경과 배움의 기회를 제공하는 데
                                사용됩니다.
                            </p>
                            <small className="text-point">
                                <b>※ 소중한 후원을 주시는 후원자님들께서는 기부금 공제 혜택을 받으실 수 있습니다.</b>
                            </small>
                        </div>
                    </div>
                </div>
                <div className="relative w-full h-full py-14 lg:py-28 bg-light">
                    <div data-aos="fade-up" className="relative w-full h-fit wrap">
                        <div>
                            <div className="flex items-center gap-3 text-point mb-5">
                                <IconHeadset size={30} />
                                <h5>전화 기부</h5>
                            </div>
                            <ul className="list-disc pl-5">
                                <li>
                                    <p className="lg:text-lg">기부신청에 대해 자세히 설명 드리겠습니다.</p>
                                </li>
                                <li>
                                    <Link to={'tel:02-747-1680'}>
                                        <p className="lg:text-lg">기부 담당자 직통 : 02-747-1680</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="my-10 lg:my-20">
                            <div className="flex items-center gap-3 text-point mb-5">
                                <IconBuildingBank size={30} />
                                <h5>무통장입금 기부</h5>
                            </div>
                            <ul className="list-disc pl-5">
                                <li>
                                    <p className="lg:text-lg">기부신청서 작성 후 재단 이메일로 보내주세요.</p>
                                </li>
                            </ul>
                            <div className="my-5 relative w-full bg-white rounded-md py-5">
                                <div className="relative w-fit grid gap-3 mx-auto">
                                    <p>※ 후원계좌 : 신한은행 140-014-390066 (재)희우장학재단</p>
                                    <p>※ 이메일 : 2sunheee@naver.com</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-5 text-center">
                                <Link to={'/file/테스트파일.hwpx'} download>
                                    <div className="relative w-full h-fit py-2 bg-point text-white rounded-full">
                                        <p className="lg:text-lg">기부신청서(.doc)</p>
                                    </div>
                                </Link>
                                <Link to={'/file/테스트파일.hwpx'} download>
                                    <div className="relative w-full h-fit py-2 bg-point text-white rounded-full">
                                        <p className="lg:text-lg">기부신청서(.hwp)</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-3 text-point mb-5">
                                <IconMapPin size={30} />
                                <h5>방문 기부</h5>
                            </div>
                            <ul className="list-disc pl-5">
                                <li>
                                    <p className="lg:text-lg">
                                        본 재단으로 직접 방문하여 기부금을 전달해주셔도 됩니다.
                                    </p>
                                </li>
                            </ul>
                            <Link to="/location">
                                <p className="lg:text-lg text-point">
                                    ※&nbsp;&nbsp;서울시 종로구 창경궁로 264, 6층 (지도 확인)
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="relative w-full h-full py-14 lg:py-28 bg-point text-white">
                    <div data-aos="fade-up" className="relative w-full h-full wrap text-center">
                        <div className="grid gap-5">
                            <h3>기부금은 어디에 사용되나요?</h3>
                            <div>
                                <p className="lg:text-lg">
                                    장학금 지원, 학습 지원, 상담 지원 등 학생들을 위한 지원사업(사회복지사업)에
                                    사용되며,
                                </p>
                                <p className="lg:text-lg">자세한 내용은 사업비 집행내역에서 확인할 수 있습니다.</p>
                            </div>
                            <div className="relative w-fit h-fit mx-auto border rounded-md py-2.5 px-10 hoverBtn">
                                <Link to={'/notice?categoryId=3'}>
                                    <p>사업비 집행내역 보기</p>
                                </Link>
                            </div>
                        </div>
                        <div className="relative w-full h-full grid grid-cols-3 gap-1 lg:gap-5 mt-14 lg:mt-28">
                            <div className="relative w-full h-full">
                                <div className="gradient relative w-full h-32 lg:h-[350px] overflow-hidden rounded-md">
                                    <img
                                        className="!relative w-full h-full object-cover object-center lg:hover:scale-125 transition-transform duration-700"
                                        src={"/images/project1.jpg"}
                                        alt="project1"
                                        width={2000}
                                        height={1335}
                                    />
                                </div>
                                <h5 className="relative mt-5 lg:text-3xl">장학금지원</h5>
                            </div>
                            <div className="relative w-full h-full">
                                <div className="gradient relative w-full h-32 lg:h-[350px] overflow-hidden rounded-md">
                                    <img
                                        className="!relative w-full h-full object-cover object-center lg:hover:scale-125 transition-transform duration-700"
                                        src={"/images/project2.jpg"}
                                        alt="project1"
                                        width={2000}
                                        height={1335}
                                    />
                                </div>
                                <h5 className="relative mt-5 lg:text-3xl">학습지원</h5>
                            </div>
                            <div className="relative w-full h-full">
                                <div className="gradient relative w-full h-32 lg:h-[350px] overflow-hidden rounded-md">
                                    <img
                                        className="!relative w-full h-full object-cover object-center lg:hover:scale-125 transition-transform duration-700"
                                        src={"/images/project3.jpg"}
                                        alt="project1"
                                        width={2000}
                                        height={1335}
                                    />
                                </div>
                                <h5 className="relative mt-5 lg:text-3xl">상담지원</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contribution;
