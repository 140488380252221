export const category = [
    { id: 1, name: "공지사항" },
    { id: 2, name: "재단활동" },
    { id: 3, name: "기부금 모금액 및 활용실적" },
    { id: 4, name: "기타" },
  ];
  
  export const main_all_category = [
    { id: 0, name: "ALL" },
    { id: 1, name: "공지사항" },
    { id: 2, name: "재단활동" },
    { id: 3, name: "기부금 모금액 및 활용실적" },
    { id: 4, name: "기타" },
  ];
  