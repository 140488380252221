import { IconCirclePlus } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";

interface DataProps {
  total: number;
  new: number;
  today: number;
  month: number;
}

const ThreeInquiry = () => {
  const [data, setData] = useState<DataProps>({
    total: 0,
    new: 0,
    today: 0,
    month: 0,
  });

  useEffect(() => {
    axios.get(`/api/inquiry/total`).then((res) => setData(res.data.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full h-fit mb-10 grid grid-cols-2 lg:grid-cols-4 items-center gap-5 text-right">
      <div
        onClick={() => (window.location.href = "/admin/inquiry")}
        className="relative w-full h-full border border-slate-100 bg-white shadow-md py-5 px-4 rounded-lg cursor-pointer"
      >
        <p>신규 문의글</p>
        <div className="flex gap-2 items-end leading-5 mt-5">
          <h3 className="-mb-2">{data.new || 0}</h3>
          <small className="opacity-60">count</small>
        </div>
        <div className="absolute right-4 bottom-5 text-adminMain">
          <IconCirclePlus stroke={1.5} size={22} />
        </div>
      </div>
      <div
        onClick={() => (window.location.href = "/admin/inquiry")}
        className="relative w-full h-full border border-slate-100 bg-white shadow-md py-5 px-4 rounded-lg cursor-pointer"
      >
        <p>오늘 문의글</p>
        <div className="flex gap-2 items-end leading-5 mt-5">
          <h3 className="-mb-2">{data.today || 0}</h3>
          <small className="opacity-60">count</small>
        </div>
        <div className="absolute right-4 bottom-5 text-adminMain">
          <IconCirclePlus stroke={1.5} size={22} />
        </div>
      </div>
      <div
        onClick={() => (window.location.href = "/admin/inquiry")}
        className="relative w-full h-full border border-slate-100 bg-white shadow-md py-5 px-4 rounded-lg cursor-pointer"
      >
        <p>이번달 문의글</p>
        <div className="flex gap-2 items-end leading-5 mt-5">
          <h3 className="-mb-2">{data.month || 0}</h3>
          <small className="opacity-60">count</small>
        </div>
        <div className="absolute right-4 bottom-5 text-adminMain">
          <IconCirclePlus stroke={1.5} size={22} />
        </div>
      </div>
      <div
        onClick={() => (window.location.href = "/admin/inquiry")}
        className="relative w-full h-full border border-slate-100 bg-white shadow-md py-5 px-4 rounded-lg cursor-pointer"
      >
        <p>총 문의글</p>
        <div className="flex gap-2 items-end leading-5 mt-5">
          <h3 className="-mb-2">{data.total || 0}</h3>
          <small className="opacity-60">count</small>
        </div>
        <div className="absolute right-4 bottom-5 text-adminMain">
          <IconCirclePlus stroke={1.5} size={22} />
        </div>
      </div>
    </div>
  );
};

export default ThreeInquiry;
