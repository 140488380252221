import SubTitle from 'components/sub/SubTitle';

const AboutGreetings = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle src={'/images/subtitle1.jpg'} title="설립자 인사말" />
            <div className="relative w-full h-fit py-14 lg:py-28">
                <div className="relative w-full h-full wrap">
                    <h5 className="text-2xl">희우장학재단 홈페이지를 방문해 주신 여러분 감사합니다.</h5>
                    <div className="grid gap-10 mt-10 lg:mt-16 mb-16 lg:mb-20 text-neutral-500">
                        <p className="lg:text-base">
                            대한민국 헌법에는 <br />
                            <b>&ldquo;모든 국민은 능력에 따라 균등하게 교육을 받을 권리를 가진다.&rdquo;</b>
                            <br /> 라고 명시되어 있습니다.
                        </p>
                        <p className="lg:text-base">
                            스스로 배움을 갈망해도 <br /> 실상 고등까지의 교육을 이어 갈 수 없는 사각지대에 놓여진
                            학생들에게 마땅히 기회를 제공 하고자 하셨던
                            <br />
                            <b>신흥우 회장님</b>의 유지를 받들어 남겨주신 출연금으로 2023년. 『희우장학재단』이
                            설립되었습니다.
                        </p>
                        <p className="lg:text-base">
                            희우장학재단은 장학금지원, 학습지원, 상담지원 등 제반 공익사업을 통해 <br />
                            초, 중, 고, 대학생을 대상으로 꿈을 이어 갈 수 있는 발판을 만들어주고자 합니다.
                        </p>
                        <p className="lg:text-base">
                            <span className="pp lg:text-xl ">喜雨</span>
                            <br />
                            기회가 간절한 이들에게, 마른 꿈에 단비가 되길 바라며.
                        </p>
                    </div>
                    <div>
                        <p className="lg:text-base text-neutral-500">희우장학재단 이사장</p>
                        <p>신 보 연</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGreetings;
