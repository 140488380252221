import { useState } from "react";

import { JoinNotice } from "const/table";
import { Link } from "react-router-dom";
import BoardCategory from "./BoardCategory";
import { dateFormat } from "const/dateFormat";

const Notice = ({ notice }: { notice: JoinNotice[] }) => {
  const [categoryId, setCategoryId] = useState<number>(0);
  return (
    <div className="relative w-full h-it py-14 lg:py-28 z-0">
      <div className="relative w-full h-full wrap min-h-[450px]">
        <div className="title">
          <h4 className="section-title font-black">재단이야기</h4>
        </div>
        <div className="flex justify-between items-center py-5">
          <BoardCategory
            categoryId={categoryId}
            setCategoryId={setCategoryId}
          />
          <Link to="/notice" className="hidden lg:block">
            <p className="lg:text-lg text-neutral-500">전체보기</p>
          </Link>
        </div>
        <div
          data-aos="fade-up"
          className="opacity-0 home_notice relative w-full h-full grid grid-cols-1 lg:grid-cols-4 items-start gap-5"
        >
          {categoryId === 0
            ? notice.map(
                (v, i) =>
                  i < 4 && (
                    <div
                      key={v.category.name! + i}
                      onClick={() =>
                        (window.location.href =
                          v.category.id === 2
                            ? `/activity/${v.id}`
                            : `/notice/${v.id}`)
                      }
                      className="borderLine relative w-full h-full py-3 lg:py-10 px-3 border rounded-md cursor-pointer"
                    >
                      <div className="relative w-full h-fit grid gap-5">
                        <small className="lg:text-sm">
                          <b>{v.category.name}</b>
                        </small>
                        <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all text-base lg:text-xl">
                          {v.title}
                        </div>
                        <small className="opacity-50 lg:text-sm">
                          {dateFormat(v.createdAt)}
                        </small>
                      </div>
                    </div>
                  )
              )
            : notice
                .filter((data) => data.category.id === categoryId)
                .map(
                  (v, i) =>
                    i < 4 && (
                      <div
                        key={v.category.name! + i}
                        onClick={() =>
                          (window.location.href =
                            v.category.id === 2
                              ? `/activity/${v.id}`
                              : `/notice/${v.id}`)
                        }
                        className="borderLine relative w-full h-full py-3 lg:py-10 px-3 border rounded-md cursor-pointer"
                      >
                        <div className="relative w-full h-fit grid gap-5">
                          <small className="lg:text-sm">
                            <b>{v.category.name}</b>
                          </small>
                          <div className="relative w-full h-fit overflow-hidden whitespace-nowrap text-ellipsis break-all text-base lg:text-xl">
                            {v.title}
                          </div>
                          <small className="opacity-50 lg:text-sm">
                            {dateFormat(v.createdAt)}
                          </small>
                        </div>
                      </div>
                    )
                )}
        </div>
      </div>
    </div>
  );
};

export default Notice;
