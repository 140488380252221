import { NoticeFileProps } from "../board/BoardThumb";

import style from "./RuleUpload.module.css";

const FileUpload = ({
  file,
  setFile,
}: {
  file: NoticeFileProps;
  setFile: React.Dispatch<React.SetStateAction<NoticeFileProps>>;
}) => {
  return (
    <div className={style.filebox + " relative w-fit mx-auto"}>
      <input
        className="upload-name border h-fit py-2.5 px-2 my-2"
        value={(file && file.name) || "정관 업로드"}
        placeholder="정관 업로드"
        readOnly
      />
      <label htmlFor="file">파일찾기</label>
      {file.name !== "" ? (
        <input
          type="text"
          id="file"
          defaultValue={""}
          readOnly
          onClick={() =>
            alert("기존에 등록되어 있는 파일을 먼저 삭제해주세요.")
          }
        />
      ) : (
        <input
          type="file"
          id="file"
          defaultValue={""}
          onChange={(e) => {
            e.target.files &&
              setFile({
                name: e.target.files[0].name,
                file: e.target.files[0],
              });
          }}
        />
      )}

      {file.name === "" && (
        <p className="text-xs lg:text-sm text-left">
          ※ 등록되어 있는 정관이 없습니다!
        </p>
      )}
    </div>
  );
};

export default FileUpload;
