import BusinessCard from 'components/sub/BusinessCard';
import { SubTitle2 } from 'components/sub/SubTitle';

const Business = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle2 />
            <div className="relative w-full h-fit py-14 lg:py-28 text-center">
                <div className="mb-14">
                    <div className="grid gap-2 mb-5">
                        <h5>배움의 의지와 열정이있는 학생들에게</h5>
                        <h5>마른 꿈에 단비가 되겠습니다.</h5>
                    </div>
                    <p className="text-neutral-500">혼자 힘들어하지 않도록 학생들과 발걸음을 함께 하겠습니다.</p>
                </div>
                <BusinessCard />
            </div>
        </div>
    );
};

export default Business;
