
const BeforListBtn = ({ link }: { link: string }) => {
  return (
    <div
    onClick={() => (window.location.href = link)}
    className="relative w-fit h-fit border flex justify-center items-center mx-auto my-7 lg:my-14 py-2 px-10 rounded-sm border-black hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer"
  >
    <p>목록으로</p>
  </div>
  )
}

export default BeforListBtn