import { useEffect } from 'react';

import { useDisclosure } from '@mantine/hooks';
import { Burger } from '@mantine/core';
import { Link } from 'react-router-dom';
import { headerLink } from 'const/link';

const MHeader = () => {
    useEffect(() => {
        let header = document.querySelector<HTMLDivElement>('#header');
        let subList = document.querySelectorAll<HTMLUListElement>('#subLink');
        let headerbg = document.querySelector<HTMLDivElement>('#headerBg');

        header!.addEventListener('mouseenter', () => {
            headerbg!.style.height = '270px';
            subList.forEach((v, i) => {
                v.style.height = '160px';
            });
        });

        header!.addEventListener('mouseleave', () => {
            subList.forEach((v, i) => {
                v.style.height = '0';
            });
            headerbg!.style.height = '0';
        });
    }, []);

    // 모바일 설정
    const [opened, { toggle }] = useDisclosure();

    useEffect(() => {
        let mobileLi = document.querySelector<HTMLDivElement>('#mobileList');
        if (opened) {
            // mobileLi!.style.display = "block";
            mobileLi!.style.transform = 'translate(0, 85px)';
        } else {
            // mobileLi!.style.display = "none";
            mobileLi!.style.transform = 'translate(0, -100vh)';
        }
    }, [opened]);
    return (
        <>
            <div id="header" className="fixed top-0 left-0 w-full h-fit bg-white py-3 lg:py-4 z-50">
                <div
                    id="headerBg"
                    className="hidden lg:block absolute top-0 left-0 w-full h-0 bg-white transition-all duration-500"
                ></div>
                <div className="relative wrap flex justify-between items-center">
                    <div className="relative w-fit h-full grid items-center">
                        <div className="relative w-full h-full max-h-16 lg:max-h-24">
                            <img
                                onClick={() => (window.location.href = '/')}
                                className="!relative w-auto h-full object-contain cursor-pointer"
                                alt="logo"
                                src={'/images/logo.png'}
                                width={1000}
                                height={462}
                            />
                        </div>
                    </div>
                    <div className="hidden relative w-full h-full lg:flex justify-end items-center">
                        <ul className="relative flex gap-10">
                            {headerLink.map((v, i) => (
                                <li key={i}>
                                    <Link to={v.link} target={v.link.includes('https') ? '_blank' : '_self'}>
                                        <p className="lg:text-lg">{v.title}</p>
                                    </Link>
                                    {v.sub && (
                                        <ul
                                            id="subLink"
                                            className="absolute top-12 h-0 overflow-hidden transition-all duration-500 z-10"
                                        >
                                            {v.sub.map((v, i) => (
                                                <li key={i} className="py-2 pl-1 text-zinc-600">
                                                    <Link to={v.link}>
                                                        <p className="hoverLine text-base">{v.title}</p>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="block lg:hidden">
                        <Burger size="sm" opened={opened} onClick={toggle} />
                    </div>
                </div>
            </div>
            <div
                className="lg:hidden -translate-y-full fixed left-0 top-0 pt-10 w-full h-full max-h-screen bg-white transition-all duration-500 z-40"
                id="mobileList"
            >
                <ul className="relative w-fit h-fit mx-auto">
                    {headerLink.map((v, i) => (
                        <li key={i} className="py-2">
                            <Link
                                to={v.link}
                                target={v.link.includes('https') ? '_blank' : '_self'}
                                onClick={() => toggle}
                            >
                                <p className="lg:text-lg">{v.title}</p>
                            </Link>
                            {v.sub && (
                                <ul className="pl-3">
                                    {v.sub.map((v, i) => (
                                        <li key={i} className=" text-zinc-600">
                                            <Link to={v.link} onClick={() => toggle}>
                                                <p className=" text-base">{v.title}</p>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default MHeader;
