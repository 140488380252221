import axios from 'axios';
import FAQ from 'components/sub/FAQ';
import InquiryForm from 'components/sub/InquiryForm';
import { SubTitle3 } from 'components/sub/SubTitle';
import { useEffect, useState } from 'react';

const ContactQnA = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('/api/qna/list').then((res) => setData(res.data.data));
    }, []);
    return (
        <div className=" relative w-full h-full">
            <SubTitle3
                src={'/images/subtitle4.jpg'}
                title="문의하기"
                sub="궁금한 사항 또는 불편사항, 사업 관련 의견 제안 등에 대해 답변 받으실 수 있습니다."
            />
            <div className="relative w-full h-fit pt-14 lg:pt-28">
                <FAQ qna={data} />
            </div>
            <div className="relative w-full h-fit py-14 lg:py-28">
                <InquiryForm />
            </div>
        </div>
    );
};

export default ContactQnA;
