import { IconDownload } from "@tabler/icons-react";

import { Rule } from "const/table";
import { Link } from "react-router-dom";

const RuleDownload = ({ rule }: { rule: Rule[] | null }) => {
  return (
    <div className="grid gap-10 mt-10 lg:mt-16 mb-16 lg:mb-20 text-neutral-500">
      <div className="lg:flex items-center gap-10">
        <p className="lg:text-base mb-5 lg:mb-0">정관 내려받기</p>
        {rule && rule.length > 0 ? (
          <Link
            to={`/api/rule/download?id=${rule[0].id}`}
            target={"_blank"}
            className="relative w-fit h-fit flex justify-center items-center gap-2 border border-black py-2 px-5 cursor-pointer"
          >
            <p className="lg:text-base">희우장학재단_정관</p>
            <IconDownload />
          </Link>
        ) : (
          <div
            onClick={() => alert("준비중입니다.")}
            className="relative w-fit h-fit flex justify-center items-center gap-2 border border-black py-2 px-5 cursor-pointer"
          >
            <p className="lg:text-base">희우장학재단_정관</p>
            <IconDownload />
          </div>
        )}
      </div>
    </div>
  );
};

export default RuleDownload;
