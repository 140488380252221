import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

const Editor = ({ data, setData }: { data: any; setData: any }) => {
  const customUploadAdapter = (loader: { file: Promise<any> }) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append("file", file);

            axios
              .post("/api/images", formData)
              .then((res) => {
                resolve({
                  default: res.data.data.path + res.data.data.saveName,
                });
              })
              .catch((err) => reject(err));
          });
        });
      },
    };
  };
  function uploadPlugin(editor: {
    plugins: {
      get: (arg0: string) => {
        (): any;
        new (): any;
        createUploadAdapter: (loader: any) => { upload(): Promise<unknown> };
      };
    };
  }) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };
  }
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        console.log("Editor is ready to use!", editor);
      }}
      config={{ extraPlugins: [uploadPlugin] }}
      onChange={(event, editor) => {
        setData(editor.getData());
      }}
      onBlur={(event, editor) => {
        console.log("Blur.", editor);
      }}
      onFocus={(event, editor) => {
        console.log("Focus.", editor);
      }}
    />
  );
};

export default Editor;
