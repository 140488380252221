import axios from "axios";
import { dateFormat } from "const/dateFormat";
import { useEffect, useState } from "react";

const Board = ({ category }: { category: number }) => {
  const [data, setData] = useState<any[] | null>(null);

  useEffect(() => {
    !data &&
      axios.get(`/api/board/category/${category}?page=0&size=4`).then((res) => {
        console.log(res.data);
        setData(res.data.data.content);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-full max-w-[100vw]">
      {data &&
        data.map((v, i) => (
          <li
            key={v.category.name! + i}
            onClick={() =>
              (window.location.href =
                v.category.id === 5
                  ? `/admin/application/${v.id}`
                  : `/admin/board/${v.id}`)
            }
            className="borderLine relative w-full h-fit py-2 grid cursor-pointer hover:bg-light transition-all duration-300"
          >
            <span className="relative w-full h-fit flex justify-between items-start">
              <p>
                <b>{v.title}</b>
              </p>
              <p className="lg:text-sm text-neutral-500">
                {dateFormat(v.createdAt)}
              </p>
            </span>
            <small>{v.category.name}</small>
          </li>
        ))}
    </div>
  );
};

export default Board;
