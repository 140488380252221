const MFooter = () => {
    return (
        <footer className="relative w-full h-fit py-14 bg-black text-white">
            <div className="relative w-full h-full wrap lg:flex items-start justify-between">
                <div className="relative w-full h-full">
                    <p className="lg:text-3xl mb-8">재단법인 희우장학재단</p>
                    <ul className="grid gap-3">
                        <li>
                            <small>서울특별시 종로구 창경궁로 264, 6층</small>
                        </li>
                        <li>
                            <small className="opacity-60">COPYRIGHT © 2023 희우장학재단 ALL RIGHTS RESERVED.</small>
                        </li>
                    </ul>
                </div>
                <div className="relative w-fit h-full">
                    <ul className="flex items-center gap-5">
                        <li>
                            <a href="/privacy">
                                <small className="hoverLine">개인정보처리방침</small>
                            </a>
                        </li>
                        <li>
                            <a href="/terms">
                                <small className="hoverLine">이용약관</small>
                            </a>
                        </li>
                        <li>
                            <a href="/qna">
                                <small className="hoverLine">문의하기</small>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default MFooter;
