import { useEffect, useRef } from 'react';

const Project = () => {
    const target = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let observer: IntersectionObserver;

        if (target) {
            observer = new IntersectionObserver(
                ([e]) => {
                    const target = e.target as HTMLElement;
                    if (e.isIntersecting) {
                        target.classList.add('onProcess');
                    } else {
                        target.classList.remove('onProcess');
                    }
                },
                { threshold: 0.2 }
            );
            observer.observe(target.current as Element);
        }
    }, [target]);

    useEffect(() => {
        const cardList = document.querySelectorAll<HTMLDivElement>('#card');

        cardList.forEach((card, i) => {
            card.addEventListener('mouseenter', () => {
                card.classList.add('hover');
            });
            card.addEventListener('mouseleave', () => {
                card.classList.remove('hover');
            });
        });
    }, []);
    return (
        <div className="project relative w-full h-it py-14 lg:py-28 bg-light z-0 transition-all duration-500">
            <div ref={target} className="relative w-full h-full wrap">
                <div className="title mb-5 lg:mb-10">
                    <h4 className="opacity-0 section-title font-black">진행사업</h4>
                    <p className="opacity-0 text-sm lg:text-lg text-neutral-500">
                        희우장학재단은 초, 중, 고, 대학생을 대상으로 꿈을 이어갈 수 있는 발판을 만들어주고자 합니다.
                    </p>
                </div>
                <div className="hidden lg:grid grid-rows-3 gap-10">
                    <ProjectCardLeft src={'/images/project1.jpg'} txt1="초·중·고 학생들을 위한" txt2="장학금 지원" />
                    <ProjectCardRight />
                    <ProjectCardLeft src={'/images/project3.jpg'} txt1="멘토링 및 네트워킹 프로그램" txt2="상담 지원" />
                </div>
            </div>
            <div className="relative w-full h-fit lg:hidden grid grid-rows-3 gap-5">
                <ProjectCardLeftMo src={'/images/project1.jpg'} txt1="초·중·고 학생들을 위한" txt2="장학금 지원" />
                <ProjectCardRightMo />
                <ProjectCardLeftMo src={'/images/project3.jpg'} txt1="멘토링 및 네트워킹 프로그램" txt2="상담 지원" />
            </div>
        </div>
    );
};

export default Project;

export const ProjectCardLeft = ({ src, txt1, txt2 }: { src: string; txt1: string; txt2: string }) => {
    return (
        <div
            id="card"
            className="relative w-full h-48 overflow-hidden flex items-center"
            onClick={() => (window.location.href = '/business')}
        >
            <div id="imgArea" className="relative opacity-0 w-4/5 h-full ">
                <div className="relative w-full h-full">
                    <img
                        className="!relative w-full h-full object-cover object-center"
                        src={src}
                        alt="project1"
                        width={2000}
                        height={1335}
                    />
                    <div
                        id="imgText"
                        className="opacity-0 absolute left-0 top-0 w-full h-full bg-black bg-opacity-70 grid items-center text-center cursor-pointer"
                    >
                        <h4 className="text-white pp">{txt2} &gt;</h4>
                    </div>
                </div>
            </div>
            <div id="textArea" className="relative w-1/5 h-full pl-8 right-0 -translate-x-1/2 grid items-end">
                <div className="pb-3">
                    <small className="text-gray-500">{txt1}</small>
                    <h5 className="">{txt2}</h5>
                </div>
            </div>
        </div>
    );
};
export const ProjectCardRight = () => {
    return (
        <div
            id="card"
            className="right relative w-full h-48 overflow-hidden flex justify-between items-center"
            onClick={() => (window.location.href = '/business')}
        >
            <div id="textArea" className="relative w-1/5 h-full pr-8 left-0 translate-x-1/2 text-right grid items-end">
                <div className="pb-3">
                    <small className="text-gray-500">학습에 집중할 수 있도록</small>
                    <h5>학습 지원</h5>
                </div>
            </div>
            <div id="imgArea" className="relative opacity-0 w-4/5 h-full">
                <div className="relative w-full h-full">
                    <img
                        className="!relative w-full h-full object-cover object-bottom"
                        alt="project2"
                        src={'/images/project2.jpg'}
                        width={2000}
                        height={1335}
                    />
                    <div
                        id="imgText"
                        className="opacity-0 absolute left-0 top-0 w-full h-full bg-black bg-opacity-70 grid items-center text-center cursor-pointer"
                    >
                        <h4 className="text-white pp">학습 지원 &gt;</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};
export const ProjectCardLeftMo = ({ src, txt1, txt2 }: { src: string; txt1: string; txt2: string }) => {
    return (
        <div
            data-aos="fade-up"
            id="card_mo"
            className="relative w-full h-fit pl-5"
            onClick={() => (window.location.href = '/business')}
        >
            <div>
                <img
                    className="!relative w-full h-28 object-cover object-center"
                    src={src}
                    alt="project1"
                    width={2000}
                    height={1335}
                />
            </div>
            <div>
                <div className="pt-2">
                    <small className="text-gray-500">{txt1}</small>
                    <h4 className="pt-1">{txt2}</h4>
                </div>
            </div>
        </div>
    );
};
export const ProjectCardRightMo = () => {
    return (
        <div
            data-aos="fade-up"
            id="card_mo"
            className="relative w-full h-fit pr-5"
            onClick={() => (window.location.href = '/business')}
        >
            <div>
                <img
                    className="!relative w-full h-28 object-cover object-center"
                    alt="project2"
                    src={'/images/project2.jpg'}
                    width={2000}
                    height={1335}
                />
            </div>
            <div className="text-right">
                <div className="pt-2">
                    <small className="text-gray-500">학습에 집중할 수 있도록</small>
                    <h4 className="pt-1">학습 지원</h4>
                </div>
            </div>
        </div>
    );
};
